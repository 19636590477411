<template>
    <div>
        <div
            class="question-info"
            :class="{open: open}"
            @click="$emit('click', $event)"
        >
            <div class="question__short-info">
                <div
                    v-if="question.is_override_title"
                    class="question__title"
                >
                    <div class="number">
                        {{
                            question.title || ''
                        }}
                    </div>
                </div>
                <div
                    v-else
                    class="question__title"
                >
                    <div class="number">
                        {{ $t('dash_question', 'Question') }}
                        {{ index + 1 }}
                    </div>
                </div>
                <div class="question__text">
                    <div
                        v-html="question.name"
                    ></div>
                    <div
                        class="view"
                        v-html="question.intro"
                    ></div>
                </div>
                <div class="question__actions-block">
                    <notificationIcon
                        v-if="question.is_unread"
                        class="question__action question__notification"
                    />
                    <img
                        v-if="isShowDone(question)"
                        src="@/assets/img/icons/done_icon.svg"
                        class="question__action question__done"
                    />
                    <img
                        v-if="question.is_private"
                        class="private-comment-icon"
                        src="@/assets/img/icons/Private_question_icon.svg"
                        alt="Иконка приватного вопроса"
                    />
                    <div class="question__open-button">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="open"
            class="question-details"
        >
            <div
                v-if="!question.hide_tool || question.format === 3"
                class="meaning-block__actions"
            >
                <div
                    v-if="!question.hide_tool"
                    class="row"
                >
                    <app-button
                        class="narrow"
                        :data-disable="!enabledActions"
                        @click="openPopup(0, 0, questionId)"
                    >
                        <img
                            src="@/assets/img/icons/reply_icon.svg"
                        />
                        {{ $t('dash_reply', 'Reply') }}
                    </app-button>
                    <app-button
                        class="narrow purple"
                        :data-disable="!enabledActions"
                        @click="toggleRecorder"
                    >
                        <img
                            src="@/assets/img/icons/voice_icon.svg"
                        />
                        {{
                            $t('dash_reply_voice', 'Reply via Voice')
                        }}
                    </app-button>
                    <app-button
                        class="narrow"
                        :data-disable="!enabledActions"
                        @click="openPopup(0, 1, questionId)"
                    >
                        <img
                            src="@/assets/img/icons/question-cloud_icon.svg"
                        />
                        {{
                            $t('dash_reply_all', 'Question to All')
                        }}
                    </app-button>
                    <app-file-input
                        class="narrow"
                        :load-file="uploadCommentDocument"
                        :data-disable="!enabledActions"
                    >
                        <img
                            src="@/assets/img/icons/upload_icon.svg"
                        />
                        {{
                            $t('dash_reply_doc', 'Upload Documents')
                        }}
                    </app-file-input>
                    <app-gpt
                        :question-id="questionId"
                        :name="'textPanel'"
                    />
                </div>
                <div
                    v-if="!!question.is_file_uploaded"
                    class="row"
                >
                    <app-button
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'document-review', query: {id: topicId, event_id: eventId, question_id: questionId}}"
                    >
                        <i class="fa fa-eye"></i>
                        {{ $t('dash_review', 'Join Review') }}
                    </app-button>
                </div>
            </div>
            <div class="question-files">
                <app-audio-recorder
                    v-if="recorderIsActive"
                    :load-voice="uploadVoiceComment"
                    @close="toggleRecorder"
                />
                <app-videos-gallery
                    v-if="showVideoOnTopic(question)"
                    :key="dashboard.id"
                    class="column"
                    :videos="question.videos"
                />
                <app-audios-gallery
                    v-if="showAudioOnTopic(question)"
                    :key="dashboard.id + 1"
                    class="column"
                    :audios="question.audios"
                />
                <template
                    v-if="showDocumentsOnTopic(question)"
                >
                    <app-resources
                        v-for="document of question.documents"
                        :key="document.id "
                        class="resource"
                    >
                        <a
                            v-if="!document.secured"
                            :href="generateFileUrl(document.file)"
                            target="_blank"
                            @click="sendAnalytic(1 ,document.id)"
                        >
                            <PDFIcon />
                            {{ document.name }}
                        </a>
                        <router-link
                            v-else
                            :to="{ name:'view', query:{ document_id: document.id, part: 'dashboard' } }"
                            target="_blank"
                            @click.native="sendAnalytic(1 ,document.id)"
                        >
                            <PDFIcon />
                            {{ document.name }}
                        </router-link>
                        <p>
                            {{ document.description }}
                        </p>
                    </app-resources>
                </template>
            </div>
            <br v-if="showVideoOnTopic || showDocumentsOnTopic || showAudioOnTopic" />
            <app-comments
                v-if="showComments(question) && !question.hide_tool"
                :comments="question.comments"
                :question-id="questionId"
                :topic-is-active="enabledActions"
                @like="likeComment"
                @comment="openPopup"
                @edit="editPopup"
                @toggleCommentState="toggleCommentState"
            />

            <template
                v-if="question.surveys.length"
            >
                <div class="app-topic-title">
                    {{ $t('dash_survey', 'Survey') }}
                </div>
                <div
                    class="survey"
                    :disabled="!enabledActions"
                >
                    <template
                        v-for="survey in question.surveys"
                    >
                        <app-survey-question
                            v-if="survey.type !== 6"
                            :key="survey.id"
                            :survey="survey"
                            :socket="socket"
                        />
                        <table-survey-component
                            v-else
                            :key="survey.id"
                            :table="survey"
                            :question-id="questionId"
                        />
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import notificationIcon from '@/assets/img/icons/notification_icon.svg?inline';
import appComments from '@/components/user/app/app-comments/index.vue';
import appAudioRecorder from '@/components/user/app/app-audio-recorder/index.vue';
import appResources from '@/components/user/app/app-resources/index.vue';
import tableSurveyComponent from '@/components/user/pages/dashboard/table-survey-component.vue';
import appButton from '@/components/user/app/app-button/index.vue';
import appSurveyQuestion from '@/components/user/app/app-survey-question/index.vue';
import appGpt from '@/components/user/app/app-gpt/index.vue';
import appVideosGallery from '@/components/user/app/app-videos-gallery/index.vue';
import appAudiosGallery from '@/components/user/app/app-audios-gallery/index.vue';
import appFileInput from '@/components/user/app/app-file-input/index.vue';
import { generateFileUrl, post } from '@/utils/http-helper';
import { GP } from '@/utils/sockets-helper';
import {sendClick} from '@/utils/user/clicks-analytic';
import PDFIcon from '@/assets/img/icons/pdf_icon.svg?inline';

const NAME_SPACE = 'dashboard';
const UPLOAD_VOICE = '/dashboard/upload-voice';
const UPLOAD_DOCUMENT = '/dashboard/upload-document';
const COMMENT_CREATE_FILE = 'comment-create-file';
const COMMENT_CREATE_AUDIO = 'comment-create-audio';
const COMMENT_LIKE = 'comment-like';
const COMMENT_UNLIKE = 'comment-unlike';
const COMMENT_RESTORE = 'comment-restore';
const COMMENT_REMOVE = 'comment-remove';

const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'question',
    components: {
        appFileInput,
        appAudiosGallery,
        appVideosGallery,
        appGpt,
        appSurveyQuestion,
        appButton,
        tableSurveyComponent,
        appResources,
        appAudioRecorder,
        appComments,
        notificationIcon,
        PDFIcon,
    },

    data: () => ({
        recorderIsActive: false,
    }),
    props: {
        question: {
            type: Object,
            required: true,
        },
        socket: {
            type: Object,
            required: true,
        },
        dashboard: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        questionId: {
            type: Number,
            required: true,
        },
        open: {
            type: Boolean,
            required: true,
        },
        topicIsActive: {
            type: Boolean,
            required: true,
        },
        enabledActions: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        topicId() {
            return this.$store.getters['dashboard/topicId'];
        },
        eventId() {
            return this.$route.query.event_id;
        },
    },
    methods: {
        showDocumentsOnTopic(question) {
            return !!question?.documents?.length;
        },
        showAudioOnTopic(question) {
            return !!question?.audios?.length;
        },
        showVideoOnTopic(question) {
            return !!question?.videos?.length;
        },
        showComments(question) {
            return question.comments?.length;
        },

        isShowDone(question) {
            return !!question.is_answered;
        },

        openPopup(parentId = 0, toAll = 0, questionId = 0) {
            const options = {
                topicId: this.topicId,
                parentId,
                toAll,
                questionId,
            };
            this.$store.dispatch('togglePopup', { popupName: 'commentPopup', options });
        },

        toggleRecorder() {
            this.recorderIsActive = !this.recorderIsActive;
        },

        async uploadCommentDocument(document) {
            let formData = new FormData();
            formData.append('topic_id', this.topicId);
            formData.append('file', document);
            this.questionId ? formData.append('question_id', this.questionId) : null;
            const loadStatus = await post(UPLOAD_DOCUMENT, formData, true);
            if (loadStatus.status === 1) {
                this.$store.dispatch('dashboard/createComment', loadStatus.data);
                this.socket.emit(generateSocketPath.generate(COMMENT_CREATE_FILE), {
                    comment_id: loadStatus.data.id,
                });
            }
        },

        likeComment(id, liked, questionId) {
            this.socket.emit(generateSocketPath.generate(liked ? COMMENT_UNLIKE : COMMENT_LIKE), {
                comment_id: id,
                question_id: questionId,
            }, data => {
                if (data.success) {
                    this.$store.dispatch('dashboard/changeLikesCount', {
                        id,
                        up: !liked,
                        self: true,
                        questionId,
                    });
                }
            });
        },

        editPopup(commentId = 0, questionId = 0, commentContent = '') {
            const options = {
                commentId,
                questionId,
                commentContent,
                editing: true,
            };
            this.$store.dispatch('togglePopup', { popupName: 'commentPopup', options });
        },
        async uploadVoiceComment(voice, name) {
            let formData = new FormData();
            formData.append('topic_id', this.topicId);
            formData.append('file', voice, name);
            this.questionId ? formData.append('question_id', this.questionId) : null;

            const loadStatus = await post(UPLOAD_VOICE, formData, true);

            if (loadStatus.status === 1) {
                this.$store.dispatch('dashboard/createComment', loadStatus.data);
                this.socket.emit(generateSocketPath.generate(COMMENT_CREATE_AUDIO), {
                    comment_id: loadStatus.data.id,
                });
            }
        },

        generateFileUrl(file) {
            return generateFileUrl(file);
        },

        sendAnalytic(type, id) {
            sendClick(5, type, id, this.topicId);
        },

        toggleCommentState(id, deleted) {
            this.socket.emit(generateSocketPath.generate(deleted ? COMMENT_RESTORE : COMMENT_REMOVE), {
                comment_id: id,
            }, data => {
                if (data.success) {
                    data.deleted = +!deleted;
                    this.$store.dispatch('dashboard/toggleCommentState', data);
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.question {
    &-info {
        cursor: pointer;

        &.open {
            .question__open-button {
                span:nth-of-type(2) {
                    display: none;
                }
            }
        }
    }

    &__short-info {
        position: relative;

        padding: .9rem 1.1rem 0;

        &::v-deep {
            img {
                max-width: 100%;
            }
        }
    }

    &__title {
        font-size: to_rem(14px);
        font-weight: 700;

        &::v-deep {
            .number {
                margin-bottom: 0.5rem;

                color: var(--platform-accent-color);
            }
        }
    }

    &__text {
        margin-top: 0.5rem;

        font-size: to_rem(14px);
        font-weight: 700;
    }

    &__actions-block {
        position: absolute;
        top: -.2em;
        right: 1.25rem;

        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__open-button {
        position: relative;

        width: 0.9rem;
        height: 0.9rem;
        margin-top: 0.4rem;

        cursor: pointer;

        span {
            position: absolute;
            top: 50%;
            left: 50%;

            display: block;

            width: 100%;
            height: 2px;

            background: var(--platform-accent-color);

            transform: translate(-50%, -50%);

            &:nth-of-type(2) {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }
    }

    &__action {
        height: 1.8rem;
        margin-right: .9rem;

        cursor: pointer;
    }

    &__notification {
        width: 1.7rem;

        &::v-deep {
            path, circle {
                fill: white;
                stroke: var(--platform-accent-color);
                stroke-width: 15;
            }

            circle {
                fill: red !important;
            }

            circle:nth-last-of-type(2) {
                fill: var(--platform-accent-color) !important;
            }
        }
    }

    &__done {
        width: 3.9rem;
    }

    &-info {
        position: relative;

        margin-bottom: 1.2rem;
        padding: .55rem .4rem 1.7rem;

        background: white;
    }
}

.meaning-block {
    &__title {
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center;
        font-size: to_rem(20px);
        text-align: center;
        color: $mainTextColor;

        &-title {
            margin-left: auto;
        }

    }

    &__open-button {
        position: relative;

        width: 0.9rem;
        height: 0.9rem;
        margin-top: 0.4rem;
        margin-left: auto;

        cursor: pointer;

        span {
            position: absolute;
            top: 50%;
            left: 50%;

            display: block;

            width: 100%;
            height: 2px;

            background: var(--platform-accent-color);

            transform: translate(-50%, -50%);

            &:nth-of-type(2) {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        span {
            background: $mainTextColor;
        }

        span:nth-of-type(2) {
            display: none;
        }
    }

    &__title.close {
        .meaning-block__open-button {
            span:nth-of-type(2) {
                display: block;
            }
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 1.2rem;
        padding: 1.85rem 0;

        background: var(--platform-accent-color);

        .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .app-button {
            margin-bottom: .6rem;

            &:not(:last-child) {
                margin-right: .3rem;
            }
        }

        .accent {
            background: var(--platform-main-color);
        }
    }

    .app-comments {
        max-height: 40rem;
    }
}

@include mobile_or_P {
    .question {
        &__short-info {
            padding: 0.85rem 0.4rem 0;
        }

        &__actions-block {
            right: 0.25rem;
        }
    }

    .meaning-block {
        &__title {
            margin-bottom: 0.95rem;
        }

        &__actions {
            margin-bottom: 0.65rem;
            padding: .5rem;

            .app-button {
                margin: 0.5rem 0.2rem !important;
            }
        }

        .app-language-select {
            margin-top: 0.5rem;
        }

        .app-comments {
            max-height: 30rem;
        }
    }
}

@include razr767 {
    .question {
        &-info {
            margin-bottom: 0;
            padding: 0.8rem 1.1rem 1.5rem;
        }

        &__title {
            font-size: 1.05rem;
        }

        &__short-info {
            padding: 0.85rem 0.6rem 0;
        }

        &__text {
            margin-top: 0.7rem;

            font-size: 1.01902rem;
        }
    }

    .meaning-block {
        &__actions {
            margin-bottom: 0;
            padding: 1.4rem 0;

            .row {
                justify-content: center;
            }
        }
    }
}

.private-comment-icon {
    width: 1rem;
    height: 1rem;
    margin-top: 0.4rem;
    margin-right: 8px;
}

.survey {
    background: #fff;
    border-top: none;

    &[disabled] {
        pointer-events: none;

        &::v-deep {
            .app-tool-btn, .app-popup, .table-survey__table-wrapper {
                pointer-events: all;
            }

            .table-survey {
                textarea, .checkbox, .table-survey__dropdown {
                    pointer-events: none;
                }
            }
        }
    }
}
</style>
