<template>
    <div>
        Logout....
    </div>
</template>

<script>
import { logoutTopicTimer } from '@/utils/user/time-socket';

export default {
    name: 'logout',

    async beforeCreate() {
        logoutTopicTimer();
        await this.$store.dispatch('auth/logout');
        this.$router.push({ name: 'login' });
    },
};
</script>

<style scoped lang="scss">

</style>
