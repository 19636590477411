import login from '@/views/login';
import logout from '@/views/logout.vue';
import error404 from '@/views/Error404.vue';
import Home from '@/views/Home.vue';

import dashboard from '@/views/user/dashboard';
import agenda from '@/views/user/agenda';
import participants from '@/views/user/participants';
import eLibrary from '@/views/user/e-library';
import company from '@/views/user/company';
import guidance from '@/views/user/guidance';
import profile from '@/views/user/profile';
import itSupport from '@/views/user/it-support';
import stickersBoard from '@/views/user/stickers-board';
import workshop from '@/views/user/workshop';
import view from '@/views/user/view';
import documentReview from '@/views/user/document-review';
import editor from '@/views/user/editor';

import disposerManagers from '@/views/disposer/managers';
import disposerInterface from '@/views/disposer/interface';
import disposerEvents from '@/views/disposer/events';
import disposerVideo from '@/views/disposer/video';
import disposerReports from '@/views/disposer/reports';
import eventTopics from '@/views/disposer/event-topics';
import disposerAgenda from '@/views/disposer/agenda';
import disposerELibrary from '@/views/disposer/e-library';
import disposerCompany from '@/views/disposer/company';
import disposerParticipants from '@/views/disposer/participants';
import addEditEvent from '@/views/disposer/add-edit-event';
import addEditEventTopic from '@/views/disposer/add-edit-event-topic';
import addEditParticipants from '@/views/disposer/add-edit-participants';

import store from '../store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'home-layout',
    },
  },
  {
    path: '/:platform/login',
    name: 'login',
    component: login,
    meta: {
      layout: 'login-layout',
    },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/accessToken']) {
            const platform = store.getters['platform/platformName'] || to.params.platform;
            const user = store.getters['auth/userRole'];

            if (!store.getters['auth/userInfo']?.accept && (user.id === 5 || user.id === 6)) {
                next();
                return;
            }

            if (user.id === 5 || user.id === 6) {
                next(`/${platform}/dashboard`);
            } else {
                next(`/${platform}/disposer/managers`);
            }
        } else  {
            next();
        }
      },
  },
  {
    path: '/:platform/logout',
    name: 'logout',
    component: logout,
  },
  {
    path: '/:platform/dashboard',
    name: 'dashboard',
    component: dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:platform/agenda',
    name: 'agenda',
    component: agenda,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:platform/participants',
    name: 'participants',
    component: participants,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:platform/e-library',
    name: 'e-library',
    component: eLibrary,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:platform/company',
    name: 'company',
    component: company,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:platform/user-guidance',
    name: 'user-guidance',
    component: guidance,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:platform/profile',
    name: 'profile',
    component: profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:platform/it-support',
    name: 'it-support',
    component: itSupport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:platform/stickers-board',
    name: 'stickersBoard',
    component: stickersBoard,
    meta: {
      requiresAuth: true,
      layout: 'stickers-layout',
    },
  },
  {
    path: '/:platform/workshop',
    name: 'workshop',
    component: workshop,
    meta: {
      requiresAuth: true,
      layout: 'workshop-layout',
    },
  },
  {
    path: '/:platform/view',
    name: 'view',
    component: view,
    meta: {
      requiresAuth: true,
      layout: 'view-layout',
    },
  },
  {
    path: '/:platform/document-review',
    name: 'document-review',
    component: documentReview,
    meta: {
      requiresAuth: true,
      layout: 'review-layout',
    },
  },
  {
    path: '/:platform/editor',
    name: 'editor',
    component: editor,
    meta: {
      requiresAuth: true,
      layout: 'editor-layout',
    },
  },

  {
    path: '/:platform/disposer/managers',
    name: 'managers',
    component: disposerManagers,
    meta: {
      requiresAuthManager: true,
      requiresAuth: false,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/interface',
    name: 'interface',
    component: disposerInterface,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/events',
    name: 'events',
    component: disposerEvents,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/video-guidance',
    name: 'disposer-video-guidance',
    component: disposerVideo,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/add-event',
    name: 'add-event',
    component: addEditEvent,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/event/:id/edit-event',
    name: 'edit-event',
    component: addEditEvent,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/event/:id/event-topics',
    name: 'event-topics',
    component: eventTopics,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/event/:id/add-topic',
    name: 'add-topic',
    component: addEditEventTopic,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/event/:id/event-topics/:topicId/edit-topic',
    name: 'edit-topic',
    component: addEditEventTopic,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/event/:id/agenda',
    name: 'disposer-agenda',
    component: disposerAgenda,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/event/:id/e-library',
    name: 'disposer-e-library',
    component: disposerELibrary,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/event/:id/company',
    name: 'disposer-company',
    component: disposerCompany,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
  {
    path: '/:platform/disposer/event/:id/external-participants',
    name: 'external-participants',
    component: disposerParticipants,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
      role: 6,
    },
  },
  {
    path: '/:platform/disposer/event/:id/add-external-participant',
    name: 'add-external-participant',
    component: addEditParticipants,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
      role: 6,
    },
  },
  {
    path: '/:platform/disposer/event/:id/external-participants/:participantId/edit-external-participant',
    name: 'edit-external-participant',
    component: addEditParticipants,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
      role: 6,
    },
  },
  {
    path: '/:platform/disposer/event/:id/internal-participants',
    name: 'internal-participants',
    component: disposerParticipants,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
      role: 5,
    },
  },
  {
    path: '/:platform/disposer/event/:id/add-internal-participant',
    name: 'add-internal-participant',
    component: addEditParticipants,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
      role: 5,
    },
  },
  {
    path: '/:platform/disposer/event/:id/internal-participants/:participantId/edit-internal-participant',
    name: 'edit-internal-participant',
    component: addEditParticipants,
    role: 5,
    meta: {
      requiresAuthManager: true,
      layout: 'disposer-default-layout',
    },
  },
    {
        path: '/:platform/disposer/:id/reports',
        name: 'reports',
        component: disposerReports,
        meta: {
            requiresAuthManager: true,
            layout: 'disposer-default-layout',
        },
    },
  {
    path: '/:platform/*',
    name: 'error404',
    component: error404,
    meta: {
      layout: 'error-layout',
    },
        beforeEnter: async (to, from, next) => {
            const items = to.fullPath.split('/').filter(e => e!=='');
            if (items.length===1) {
                if (!store.getters['platform/platform']) {
                    const platform = await store.dispatch('platform/getPlatform', items[0]);
                    if (platform.status) {
                        store.dispatch('platform/setPlatformName', items[0]);
                        next({ name: 'login', params: { platform: items[0] } });
                    } else  {
                        next();
                    }
                }
            } else  {
                next();
            }
        },
  },
  {
    path: '/*',
    name: 'error404All',
    component: error404,
    meta: {
      layout: 'error-layout',
    },
  },
  {
    path: '/error',
    name: 'errorPage',
    component: error404,
    meta: {
      layout: 'error-layout',
    },
  },
];

export default routes;
