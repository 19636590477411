<template>
    <div
        v-if="dashboard !== null"
        class="dashboard"
    >
        <!-- <div class="page-title">
            {{ $t('dashboard', 'Dashboard') }}
        </div> -->
        <div
            v-if="showDashboard"
            class="content"
        >
            <div class="left">
                <app-topic>
                    <div
                        v-if="dashboard.status"
                        class="app-topic__description"
                    >
                        {{ $t('dash_curr_topic', 'Current Topic for Discussion') }}
                    </div>
                    <div
                        v-else
                        class="app-topic__description"
                    >
                        {{ $t('dash_curr_topic_in', 'Current Topic for Discussion') }}
                    </div>

                    <div class="app-topic__question">
                        {{ dashboard.name }}
                    </div>
                    <div v-if="isAllQuestionsAnswered" class="app-topic__successfully-sign">
                        <img src="@/assets/img/dashboard/completed.svg" />
                        You have successfully completed all tasks in&nbsp;the&nbsp;topic!
                    </div>
                    <div class="app-topic__description">
                        {{ $t('dash_remain_time', 'Remaining Time') }}:
                    </div>
                    <app-progress-bar :progress="dashboard.progress_elapsed" />
                    <div class="app-topic__time">
                        {{ dashboard.elapsed_at }}
                    </div>
                    <p v-html="dashboard.intro"></p>

                    <br v-if="showVideos" />
                    <app-videos-gallery
                        v-if="showVideos"
                        :key="dashboard.id"
                        class="column"
                        :videos="dashboard.intro_content.videos"
                    />
                    <br v-if="showAudios" />
                    <app-audios-gallery
                        v-if="showAudios"
                        :key="dashboard.id + 1"
                        class="column"
                        :audios="dashboard.intro_content.audios"
                    />
                    <br v-if="showDocuments" />
                    <template v-if="showDocuments">
                        <app-resources
                            v-for="document of dashboard.intro_content.documents"
                            :key="document.id"
                            class="resource"
                        >
                            <a
                                v-if="!document.secured"
                                :href="generateFileUrl(document.file)"
                                target="_blank"
                                @click="sendAnalytic(1 ,document.id)"
                            >
                                <PDFIcon />
                                {{ document.name }}
                            </a>
                            <router-link
                                v-else
                                :to="{ name:'view', query:{ document_id: document.id, part: 'dashboard' } }"
                                target="_blank"
                                @click.native="sendAnalytic(1 ,document.id)"
                            >
                                <PDFIcon />
                                {{ document.name }}
                            </router-link>
                            <p>
                                {{ document.description }}
                            </p>
                        </app-resources>
                    </template>
                    <br v-if="showVideos || showAudios || showDocuments" />

                    <p v-html="dashboard.intro_after"></p>
                    <app-language-select
                        v-if="showTranslating"
                        @change="translateTopic"
                    />
                </app-topic>

                <div
                    v-if="dashboard.format !== 1 && checkReviewDocumentInQustions()"
                    class="join-block"
                >
                    <app-button
                        v-if="dashboard.format === 2"
                        class="accent"
                        :data-disable="!topicIsActive || !dashboard.video_link || disableJoinBtn"
                        @click="joinToVideo"
                    >
                        <i class="fa fa-video-camera"></i>
                        {{ $t('dash_videocall', 'Join Video call') }}
                    </app-button>
                    <app-button
                        v-if="dashboard.format === 3"
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'document-review', query: {id: topicId, event_id: eventId}}"
                    >
                        <i class="fa fa-eye"></i>
                        {{ $t('dash_review', 'Join Review') }}
                    </app-button>
                    <app-button
                        v-if="dashboard.format === 4"
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'workshop', query: {id: topicId, event_id: eventId}}"
                    >
                        <i class="fa fa-pencil-square-o"></i>
                        {{ $t('dash_workshop', 'Join Workshop') }}
                    </app-button>
                    <app-button
                        v-if="dashboard.format === 5"
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'editor', query: {id: topicId, event_id: eventId}}"
                    >
                        <i class="fa fa-file-text"></i>
                        {{ $t('dash_document', 'Join Document') }}
                    </app-button>
                    <app-button
                        v-if="dashboard.format === 6"
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'stickersBoard', query: {id: topicId, event_id: eventId}}"
                    >
                        <i class="fa fa-sticky-note"></i>
                        {{ $t('dash_stickers', 'Join Meeting board') }}
                    </app-button>
                </div>

                <zoom-mtg
                    v-show="dashboard.format === 2 && showZoomMtgBlock"
                    ref="zoomMtgBlock"
                    @endMeeting="closeVideoCall"
                />

                <div
                    v-if="dashboard.questions || !dashboard.hide_tool"
                >
                    <template v-if="dashboard.questions && dashboard.questions.length">
                        <template v-if="sections.length">
                            <template v-for="(section, sectionIndex) in sections">
                                <div
                                    v-if="section.questions.length"
                                    :key="sectionIndex"
                                    class="questions-block mb-20"
                                >
                                    <div class="meaning-block">
                                        <div
                                            class="meaning-block__title"
                                            :class="{close: +(section.is_hidden)}"
                                            @click="section.is_hidden = +(!section.is_hidden)"
                                        >
                                            <div class="meaning-block__title-title">
                                                {{ section.title }}
                                            </div>
                                            <div class="meaning-block__open-button">
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div
                                            v-if="!section.is_hidden"
                                            class="questions-block"
                                        >
                                            <div
                                                v-for="(questionId, questionIndex) in section.questions"
                                                :key="questionIndex"
                                                class="meaning-block"
                                            >
                                                <div
                                                    v-if="!hiddenQuestions.includes(questionId)"
                                                    class="question"
                                                >
                                                    <question
                                                        :question="findQuestion(questionId)"
                                                        :index="questionIndex"
                                                        :questionId="questionId"
                                                        :open="questionIdFromRoute===questionId"
                                                        :enabledActions="enabledActions"
                                                        :socket="socket"
                                                        :dashboard="dashboard"
                                                        :topicIsActive="!!topicIsActive"
                                                        @click="toggleQuestion($event, questionId)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <div v-else-if="socket" class="questions-block mb-20">
                            <div
                                v-for="(question, questionIndex) in dashboard.questions"
                                :key="questionIndex"
                                class="meaning-block"
                            >
                                <div
                                    v-if="!hiddenQuestions.includes(question.id)"
                                    class="question"
                                >
                                    <question
                                        :question="question"
                                        :index="questionIndex"
                                        :questionId="question.id"
                                        :open="questionIdFromRoute===question.id"
                                        :enabledActions="enabledActions"
                                        :socket="socket"
                                        :dashboard="dashboard"
                                        :topicIsActive="!!topicIsActive"
                                        @click="toggleQuestion($event, question.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <template v-if="showSurveys">
                    <div class="app-topic-title">
                        {{ $t('dash_survey', 'Survey') }}
                    </div>
                    <div
                        class="survey"
                        :disabled="!enabledActions"
                    >
                        <template v-for="survey in dashboard.surveys">
                            <app-survey-question
                                v-if="survey.type !== 6"
                                :key="survey.id"
                                :survey="survey"
                                :socket="socket"
                            />
                            <table-survey-component
                                v-else
                                :key="survey.id"
                                :table="survey"
                                :class="{'event-none': survey.is_answered}"
                            />
                        </template>
                    </div>
                </template>
            </div>
            <sidebar
                :key="dashboard.id"
                :dashboard="dashboard"
            />
        </div>
        <template v-else-if="dashboard.source === 1">
            <e-library />
        </template>
        <template v-else-if="dashboard.source === 2">
            <company />
        </template>
        <template v-else>
            <div
                v-if="agenda && agenda.topics"
                class="dashboard-agenda"
            >
                <div class="app-topic-title">
                    {{ $t('dash_agenda_topics', 'Topics') }}
                </div>

                <div class="topics-list">
                    <div
                        v-for="topic of agenda.topics"
                        :key="topic.id"
                        class="topic"
                    >
                        <format-icons
                            :format="topic.format"
                            :is-conf="topic.conf"
                        />
                        <a @click.prevent="toTopic(topic)">
                            {{ topic.name }}
                        </a>
                        <p>
                            <span>
                                {{ $t('dash_timeline', 'Timeline') }}:
                            </span>
                            {{ topic.start_date }} - {{ topic.end_date }} {{
                                agenda.event.timezone
                            }}
                        </p>
                        <hr />
                    </div>
                </div>
            </div>
        </template>
        <app-popup name="commentPopup">
            <comment-popup
                :paths="$options.commentsPaths"
                :socket="socket"
                @createComment="createComment"
                @editComment="editComment"
            />
        </app-popup>

        <app-popup name="dashboard-completed-popup">
            <div class="dashboard__completed-popup">
                <img src="@/assets/img/dashboard/completed.svg" />
                <div class="dashboard__completed-popup-text">You have successfully completed all tasks in&nbsp;the&nbsp;topic!</div>
                <button class="dashboard__completed-popup-button" @click="closePopup">
                    Ok
                </button>
            </div>
        </app-popup>

        <app-popup name="dashboard-uncompleted-popup">
            <div class="dashboard__uncompleted-popup">
                <div class="dashboard__uncompleted-popup-text">You are going to leave this page.</div>
                <div class="dashboard__uncompleted-popup-text-small">Please note that you have not answered all the questions?</div>
                <div class="dashboard__uncompleted-popup-buttons">
                    <app-button class="dashboard__uncompleted-popup-button accent" @click="leaveDashboard">
                        Leave the questionnaire
                    </app-button>
                    <app-button class="dashboard__uncompleted-popup-button white" @click="closePopup">
                        Back to the questionnaire
                    </app-button>
                </div>
            </div>
        </app-popup>
    </div>
</template>

<script>
import appTopic from '@/components/user/app/app-topic';
import appProgressBar from '@/components/user/app/app-progressBar';
import appButton from '@/components/user/app/app-button';
import appLanguageSelect from '@/components/user/app/app-language-select';
import appSurveyQuestion from '@/components/user/app/app-survey-question';
import tableSurveyComponent from '@/components/user/pages/dashboard/table-survey-component';
import formatIcons from '@/components/user/pages/agenda/format-icons';
import zoomMtg from '@/components/user/pages/dashboard/zoomMtg';
import question from '@/components/user/pages/dashboard/question.vue';

import sidebar from '@/components/user/pages/dashboard/sidebar';
import appPopup from '@/components/user/app-popup';
import commentPopup from '@/components/user/app/app-comment-popup';

import ELibrary from '@/views/user/e-library';
import company from '@/views/user/company';

import { GP, socketInstance } from '@/utils/sockets-helper';
import { generateFileUrl, post } from '@/utils/http-helper';
import { changeTopicTimer, toogleTab } from '@/utils/user/time-socket';

import appVideosGallery from '@/components/user/app/app-videos-gallery';
import appAudiosGallery from '@/components/user/app/app-audios-gallery';
import appResources from '@/components/user/app/app-resources';
import PDFIcon from '@/assets/img/icons/pdf_icon.svg?inline';
import { sendClick } from '@/utils/user/clicks-analytic';

const READ_QUESTION = '/dashboard/read-messages';
const JOIN_TO_VIDEO = '/dashboard/join-videocall';

const NAME_SPACE = 'dashboard';
const CREATE_TEXT = 'comment-create-text';
const COMMENT_LIKE = 'comment-like';
const COMMENT_UNLIKE = 'comment-unlike';
const COMMENT_CREATE_FILE = 'comment-create-file';
const COMMENT_CREATE_AUDIO = 'comment-create-audio';
const COMMENT_REMOVE = 'comment-remove';
const COMMENT_EDIT = 'comment-edit';
const COMMENT_RESTORE = 'comment-restore';
const SURVEY_ANSWER = 'survey-answer';

const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'dashboard',

    components: {
        appTopic,
        appProgressBar,
        appButton,
        sidebar,
        appLanguageSelect,
        appSurveyQuestion,
        appPopup,
        commentPopup,
        formatIcons,
        tableSurveyComponent,
        zoomMtg,
        question,
        ELibrary,
        company,
        appVideosGallery,
        appAudiosGallery,
        appResources,
        PDFIcon,
    },

    commentsPaths: {
        NAME_SPACE: 'dashboard',
        CREATE_TEXT: 'comment-create-text',
        EDIT_TEXT: 'comment-edit',
    },

    data: () => ({
        language: null,
        socket: null,
        recorderIsActive: false,
        statTimer: null,
        lang: null,
        showZoomMtgBlock: false,
        disableJoinBtn: false,
        hiddenQuestions: [],
        questionId: null,
        routeNext: null,
        sections: [],
        canOpenCompletedPopup: false,
    }),

    computed: {
        dashboard() {
            return this.$store.getters['dashboard/get'];
        },
        id() {
            return this.$route.query.id;
        },
        eventId() {
            return this.$route.query.event_id;
        },
        questionIdFromRoute() {
            return +this.$route.query.question;
        },
        topicId() {
            return this.$store.getters['dashboard/topicId'];
        },
        showSurveys() {
            return this.dashboard.surveys?.length;
        },
        showDashboard() {
            return this.dashboard && this.dashboard.id;
        },
        showDocuments() {
            return this.dashboard.intro_content?.documents?.length;
        },
        showVideos() {
            return this.dashboard.intro_content?.videos?.length;
        },
        showAudios() {
            return this.dashboard.intro_content?.audios?.length;
        },
        agenda() {
            return this.$store.getters['dashboard/getAgenda'];
        },
        topicIsActive() {
            return +this.dashboard.status;
        },
        isUser() {
            const id = +this.$store.getters['auth/userRole']?.id;
            const mode = +this.$store.getters['auth/userRole']?.mode;
            return (id === 5 && mode !== 0) || id === 6;
        },
        enabledActions() {
            return !!(this.isUser && this.topicIsActive);
        },
        showTranslating() {
            return this.$store.getters['platform/platform'].settings.translating;
        },

        user() {
            return this.$store.getters['auth/userInfo'];
        },

        isAllQuestionsAnswered() {
            if (!this.dashboard) {
                return true;
            }

            const hasIsNotAnsweredQuestion = this.dashboard?.questions?.some((question) => {
                if (question.surveys && question.surveys.length && !question.surveys.some((survey) => survey.is_answered)) {
                    return true;
                }

                if (!question.hide_tool) {
                    const hasComment = question.comments?.some((comment) => comment.user_id === this.user.id);

                    return !hasComment;
                }
            });

            return !hasIsNotAnsweredQuestion;
        },
    },

    beforeRouteLeave(_to, _from, next) {
        if (this.isAllQuestionsAnswered) {
            next();
            return;
        }

        this.openUncompletedPopup(next);
    },

    watch: {
        id(value, oldValue) {
            if (+value !== +oldValue) {
                this.getDashboardInfo();
            }
        },
        isAllQuestionsAnswered(value) {
            if (value && this.canOpenCompletedPopup) {
                this.openCompletedPopup();
            }
        },
    },

    async created() {
        await this.getDashboardInfo();
        this.initStatTimer();
    },

    activated() {
        changeTopicTimer(this.topicId);
        this.initStatTimer();
    },

    deactivated() {
        this.socket.destroy();
        clearInterval(this.statTimer);
        this.statTimer = null;
    },

    methods: {
        findQuestion(id) {
            return this.dashboard.questions.find((question) => question.id === id);
        },
        sendAnalytic(type, id) {
            sendClick(5, type, id, this.topicId);
        },

        checkReviewDocumentInQustions() {
            return !this.dashboard.questions?.some(item => item.format === 3);
        },

        generateFileUrl(file) {
            return generateFileUrl(file);
        },

        toTopic(event) {
            this.$router.push({ name: 'dashboard', query: { id: event.id, event_id: this.eventId } });
        },

        async getDashboardRequest() {
            const params = {
                id: this.id ? this.id : null,
                eventId: this.eventId ? this.eventId : null,
            };
            await this.$store.dispatch('dashboard/get', params);
        },

        async getDashboardInfo() {
            this.canOpenCompletedPopup = false;

            await this.getDashboardRequest();
            if (this.showDashboard) {
                this.initialSockets();
                changeTopicTimer(this.topicId);
            } else {
                await this.$store.dispatch('dashboard/getAgenda', this.eventId ? this.eventId : null);
            }

            this.showZoomMtgBlock = this.dashboard.video_link?.includes('zoom');
            // Инициализация состояния скрытия вопросов
            this.dashboard.questions?.forEach((question) => {
                if (question.is_hide_section) {
                    this.hiddenQuestions.push(question.id);
                }
            });
            this.sections = this.dashboard.sections || [];
            if (this.questionIdFromRoute) {
                this.questionId = this.questionIdFromRoute;
            }

            setTimeout(() => {
                this.canOpenCompletedPopup = true;
            });
        },

        initStatTimer() {
            clearInterval(this.statTimer);
            this.statTimer = setInterval(async () => {
                this.$store.dispatch('dashboard/getStat', {
                    id: this.topicId,
                    event_id: this.eventId,
                });
            }, 30000);
        },

        openUncompletedPopup(next) {
            this.routeNext = next;

            this.$store.dispatch('togglePopup', { popupName: 'dashboard-uncompleted-popup' });
        },
        leaveDashboard() {
            if (this.routeNext) {
                this.routeNext();
            }

            this.routeNext = null;
            this.closePopup();
        },
        showComments(question) {
            return question.comments?.length;
        },
        initialSockets() {
            this.socket = socketInstance(NAME_SPACE, { room: `room-${this.topicId}` });
            this.socket.on(generateSocketPath.generate(CREATE_TEXT), data => {
                this.$store.dispatch('dashboard/createComment', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_LIKE), data => {
                this.$store.dispatch('dashboard/changeLikesCount', {
                    id: data.comment_id,
                    up: true,
                    questionId: data.question_id,
                });
            });
            this.socket.on(generateSocketPath.generate(COMMENT_UNLIKE), data => {
                this.$store.dispatch('dashboard/changeLikesCount', {
                    id: data.comment_id,
                    up: false,
                    questionId: data.question_id,
                });
            });
            this.socket.on(generateSocketPath.generate(COMMENT_CREATE_FILE), data => {
                this.$store.dispatch('dashboard/createComment', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_CREATE_AUDIO), data => {
                this.$store.dispatch('dashboard/createComment', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_EDIT), data => {
                this.$store.dispatch('dashboard/editComment', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_REMOVE), data => {
                data.deleted = 1;
                this.$store.dispatch('dashboard/toggleCommentState', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_RESTORE), data => {
                data.deleted = 0;
                this.$store.dispatch('dashboard/toggleCommentState', data);
            });
            this.socket.on(generateSocketPath.generate(SURVEY_ANSWER), data => {
                if (data.success) {
                    this.$store.dispatch('dashboard/answerSurvey', data);
                }
            });
        },
        toggleCommentState(id, deleted) {
            this.socket.emit(generateSocketPath.generate(deleted ? COMMENT_RESTORE : COMMENT_REMOVE), {
                comment_id: id,
            }, data => {
                if (data.success) {
                    data.deleted = +!deleted;
                    this.$store.dispatch('dashboard/toggleCommentState', data);
                }
            });
        },
        async toggleQuestion(e, id) {
            this.translateTopic(this.lang);
            const routeObj = {
                query: { ...this.$route.query },
            };
            if (this.questionId !== id) {
                await this.getDashboardRequest();
                routeObj.query.question = id;
                await post(READ_QUESTION, { question_id: +id });
                await this.$store.dispatch('dashboard/readQuestion', { id, status: false });
                this.questionId = id;
            } else {
                delete routeObj.query.question;
                this.questionId = null;
            }
            await this.$router.push(routeObj);

            setTimeout(() => {
                if (this.questionId && (e.target.getBoundingClientRect().top < 0 || e.target.getBoundingClientRect().top > window.innerHeight)) {
                    window.scrollTo({
                        top: e.target.getBoundingClientRect().top + window.pageYOffset - 70,
                    });
                }
            }, 100);
        },
        toggleQuestionBlock(id) {
            console.log(this.hiddenQuestions);
            if (this.hiddenQuestions.includes(id)) {
                this.hiddenQuestions = this.hiddenQuestions.filter(i => {
                    console.log(i, id);
                    return i !== id;
                });
            } else {
                this.hiddenQuestions.push(id);
            }
        },

        openCompletedPopup() {
            this.$store.dispatch('togglePopup', { popupName: 'dashboard-completed-popup' });
        },
        closePopup() {
            this.$store.dispatch('togglePopup', { popupName: null });
        },

        translateTopic(lang) {
            if (lang) {
                this.lang = lang;
                this.$store.commit('dashboard/updateLang', lang);
                this.$store.dispatch('dashboard/translateTopic', {
                    topic_id: this.topicId,
                    lang,
                });
            } else {
                this.$store.dispatch('dashboard/get', {
                    id: this.id ? this.id : null,
                    eventId: this.eventId ? this.eventId : null,
                });
            }
        },
        createComment(data) {
            this.$store.dispatch('dashboard/createComment', data);
        },
        editComment(data) {
            this.$store.dispatch('dashboard/editComment', data);
        },

        joinToVideo() {
            const link = this.dashboard.video_link;
            const target = this.dashboard.video_target;
            const isZoom = link.includes('zoom');
            if (isZoom && target) {
                this.startZoomMtg();
            } else {
                window.open(link, '_blank').focus();
                toogleTab(1);
            }

            if (this.isUser) {
                post(JOIN_TO_VIDEO, {
                    topic_id: this.topicId,
                });
            }
        },

        startZoomMtg() {
            this.disableJoinBtn = true;
            this.$refs.zoomMtgBlock.joinMeeting(this.dashboard.video_link);
        },

        closeVideoCall() {
            this.disableJoinBtn = false;
        },
    },

};
</script>

<style scoped lang="scss">
.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
        width: calc(100% - 18.4rem);
        padding-right: 1rem;
    }

    .join-block {
        display: flex;
        justify-content: center;

        margin-top: .8rem;
        padding: 2.8rem 0;

        background: var(--platform-main-color);
    }

    .questions-block {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0.9rem 0.7rem;

        background: var(--platform-main-color);
    }

    .question-info {
        cursor: pointer;
    }

    .meaning-block {
        &__title {
            margin-bottom: 0.8rem;
            display: flex;
            align-items: center;
            font-size: to_rem(20px);
            text-align: center;
            color: $mainTextColor;

            &-title {
                margin-left: auto;
            }

        }

        &__open-button {
            position: relative;

            width: 0.9rem;
            height: 0.9rem;
            margin-top: 0.4rem;
            margin-left: auto;

            cursor: pointer;

            span {
                position: absolute;
                top: 50%;
                left: 50%;

                display: block;

                width: 100%;
                height: 2px;

                background: var(--platform-accent-color);

                transform: translate(-50%, -50%);

                &:nth-of-type(2) {
                    transform: translate(-50%, -50%) rotate(90deg);
                }
            }

            span {
                background: $mainTextColor;
            }

            span:nth-of-type(2) {
                display: none;
            }
        }

        &__title.close {
            .meaning-block__open-button {
                span:nth-of-type(2) {
                    display: block;
                }
            }
        }
    }

    .app-language-select {
        position: absolute;
        top: 0;
        right: 0;
    }

    .topics-list {
        padding: 1.7rem 1.8rem;

        background: white;

        .topic {
            margin-bottom: 1.1rem;

            &:last-of-type {
                hr {
                    display: none;
                }
            }

            &.new {
                position: relative;

                padding-left: 3.7rem;

                &:before {
                    @include bef_aft;
                    width: 3rem;
                    height: 3rem;

                    background: url("~@/assets/img/icons/new-topic_icon.png") no-repeat center center/contain;
                }
            }

            a {
                display: block;

                margin-bottom: 0.3rem;

                font-size: to_rem(24px);
                color: var(--platform-accent-color);
                text-decoration: none;
                font-style: italic;

                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            p {
                font-size: to_rem(14px);
                font-weight: 700;

                span {
                    font-weight: 400;
                }
            }

            hr {
                margin: 1.7rem 0 0;

                border: none;
                border-top: 1px solid $lightGray;
            }
        }

        .app-labels-imgs {
            margin-bottom: 0.6rem;
        }
    }

    .question {
        margin-bottom: 1.2rem;
    }

    .view {
        font-size: initial;
        font-weight: initial;

        * {
            font-size: initial;
            font-weight: initial;
        }

        &::v-deep img {
            max-width: 100%;

            object-fit: contain;
        }
    }

    .app-topic {
        &__successfully-sign {
            display: flex;
            align-items: center;

            margin-bottom: 16px;
            padding: 12px 24px;

            font-family: Ebrima;
            letter-spacing: -0.5px;
            font-weight: 800;
            font-size: 24px;
            line-height: calc(29/24);
            color: $green;
            border: 5px solid $lightGreen;

            img {
                width: 48px;
                margin-right: 25px;
            }

            @include mobile_or_P {
                padding: 8px 16px;

                font-size: 16px;

                img {
                    width: 30px;
                    margin-right: 16px;
                }
            }
        }
    }

    .dashboard {
        &__completed-popup {
            display: flex;
            flex-direction: column;
            align-items: center;

            padding: 60px 16px 30px;

            @include mobile_or_P {
                padding: 20px 8px 16px;
            }

            &-text {
                margin-bottom: 62px;

                font-family: Ebrima;
                font-weight: 800;
                font-size: 24px;
                letter-spacing: -0.5px;
                line-height: calc(29/24);
                text-align: center;
                color: $mainDarkBlue;

                @include mobile_or_P {
                    margin-bottom: 24px;

                    font-size: 16px;
                }
            }

            &-button {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 100%;
                max-width: 281px;
                height: 76px;

                background-color: $mainBlue;
                border: none;

                font-family: Ebrima;
                font-weight: 800;
                font-size: 24px;
                color: $mainTextColor;

                cursor: pointer;

                &:hover {
                    background-color: $mainLightBlue;
                }

                @include mobile_or_P {
                    height: 40px;

                    font-size: 16px;
                }
            }

            img {
                width: 176px;
                margin-bottom: 52px;

                @include mobile_or_P {
                    width: 60px;
                    margin-bottom: 24px;
                }
            }
        }
    }
}

.survey {
    background: #fff;
    border-top: none;

    &[disabled] {
        pointer-events: none;

        &::v-deep {
            .app-tool-btn, .app-popup, .table-survey__table-wrapper {
                pointer-events: all;
            }

            .table-survey {
                textarea, .checkbox, .table-survey__dropdown {
                    pointer-events: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
    .content .left {
        width: 33rem;
    }
}

@include mobile_or_P {
    .content {
        padding-right: 0.5rem;

        .left {
            width: 100%;
            padding-right: 0.5rem;
        }

        .app-topic {
            margin-left: .7rem;
        }

        .questions-block {
            margin-left: 0.7rem;
            padding: 1rem .7rem;
        }

        .meaning-block {
            &__title {
                margin-bottom: 0.95rem;
            }

            .app-language-select {
                margin-top: 0.5rem;
            }

            .app-comments {
                max-height: 30rem;
            }
        }
    }
}

@include razr767 {
    .content {
        flex-direction: column;

        margin-left: 0;
        padding-right: 0;

        .left {
            padding-right: 0;
        }

        .app-topic {
            margin: 0 0.85rem;
        }

        .questions-block {
            margin-left: 0;
        }
    }
}

.dashboard {
    &__uncompleted-popup {
        padding: 60px 16px 30px;

        @include mobile_or_P {
            padding: 20px 8px 16px;
        }

        &-text {
            font-family: Ebrima;
            font-weight: 800;
            font-size: 24px;
            letter-spacing: -0.5px;
            line-height: calc(29/24);
            text-align: center;
            color: $mainDarkBlue;

            @include mobile_or_P {
                font-size: 18px;
            }

            &-small {
                margin-top: 16px;
                font-size: 18px;
                text-align: center;

                @include mobile_or_P {
                    font-size: 14px;
                }
            }
        }

        &-buttons {
            display: flex;
            justify-content: center;
            align-items: center;

            margin-top: 30px;

            @include mobile_or_P {
                flex-direction: column;
            }

        }
        &-button {
            margin-right: 10px;

            @include mobile_or_P {
                margin-right: 0;
                margin-bottom: 8px;
            }
        }
    }
}

.event-none {
    pointer-events: none;
}

</style>
